<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      width="330"
      min-height="190"
      max-height="200"
      outlined
      class="my-2 ma-2 pa-1"
      @click.stop="caseForm.shared && account.accountType !== 'god' ? previewCaseForm(caseForm, lavviraContent) : previewCaseForm(caseForm)"
    >
      <div class="row">
        <v-card-title
          v-if="caseForm.name.length >= 20"
          class="d-flex align-center ml-2 mt-2"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="font-weight-bold"
                style="font-size: 18px;"
                v-on="on"
              >
                {{ caseForm.name.slice(0, 18) + '...' }}
              </div>
            </template>
            <span>{{ caseForm.name }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-title
          v-else
          class="d-flex align-center ml-2 mt-2 font-weight-bold"
          style="font-size: 18px;"
        >
          {{ caseForm.name }}
        </v-card-title>
        <v-spacer />
        <v-menu
          open-on-hover
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              :class="account.accountType === 'god' ? 'mr-5 mt-n2' : 'ml-2 mt-n2'"
              v-bind="attrs"
              v-on="on"
            >
              mdi-account-group
            </v-icon>
          </template>
          <v-list
            dense
            class="pa-3"
          >
            <span
              class="ml-2"
              style="font-size: 15px;"
            >
              {{ $t('common|created_by') }}:
            </span>
            <v-list-item v-if="caseForm.onCreatedBy === 'Account' && caseForm.createdBy.accountType !== 'god'">
              <v-list-item-icon>
                <v-avatar
                  size="30px"
                  class="ml-1 mr-1"
                >
                  <img
                    v-if="caseForm.createdBy && caseForm.createdBy.avatar"
                    alt="Avatar"
                    :src="`${cfg.baseURL}downloadAvatar/${caseForm.createdBy._id}/${caseForm.createdBy.avatar.raw.filename}`"
                  >
                  <v-avatar
                    v-else
                  >
                    <v-icon
                      color="primary"
                      small
                    >
                      mdi-account
                    </v-icon>
                  </v-avatar>
                </v-avatar>
                <span
                  class="text-truncate ml-2 mt-1"
                  style="font-size: 15px;"
                >
                  {{ getName(caseForm.createdBy) }}
                </span>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-if="caseForm.createdBy.accountType === 'god'">
              <v-list-item-icon>
                <img
                  class="logo"
                  src="@/assets/Lavvira_monogramm_logo.svg"
                  height="22px"
                  width="auto"
                >
              </v-list-item-icon>
              <span
                class="ml-n6"
              >
                Lavvira
              </span>
            </v-list-item>
            <v-list-item v-if="caseForm.onCreatedBy === 'Company'">
              <v-list-item-icon class="ml-2">
                <v-icon
                  color="primary"
                >
                  mdi-domain
                </v-icon>
              </v-list-item-icon>
              <span
                style="font-size: 15px;"
                class="ml-n4"
              >
                {{ caseForm.createdBy.companyData.companyName }}
              </span>
            </v-list-item>
            <span
              class="ml-2"
              style="font-size: 15px;"
            >
              {{ $t('common|shared_with') }}:
            </span>
            <v-list-item
              v-for="acc in caseForm.sharedWith.accounts"
              :key="acc._id"
            >
              <v-avatar
                size="30px"
                class="ml-1 mr-1"
              >
                <img
                  v-if="acc && acc.avatar"
                  alt="Avatar"
                  :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
                >
                <v-avatar
                  v-else
                >
                  <v-icon
                    color="primary"
                    small
                  >
                    mdi-account
                  </v-icon>
                </v-avatar>
              </v-avatar>
              <v-list-item-title
                v-if="acc.accountType !== 'god' && acc.accountData"
                class="ml-2"
              >
                {{ getName(acc) }}
              </v-list-item-title>
              <v-list-item-title
                v-else
                class=""
              >
                {{ $t('mixed|all_lavvira_users') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="c in caseForm.sharedWith.company"
              :key="c._id"
              class="ml-2"
            >
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-domain
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-n4">
                {{ c.companyData.companyName }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(g, index) in caseForm.sharedWith.groups"
              :key="index"
            >
              <v-list-item-icon class="ml-2">
                <v-icon color="primary">
                  mdi-account-group
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-n4">
                {{ g.groupName }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="!account.comapnyId ? on : ''">
              <v-btn
                v-if="account.accountType !== 'god' && caseForm && !caseForm.shared"
                icon
                :disabled="disabledRule"
                class="mt-5 mr-n2"
                v-bind="attrs"
                @click.stop.native="shareCaseForm(caseForm)"
                v-on="on"
              >
                <v-icon
                  size="22"
                  color="primary"
                >
                  mdi-share-variant
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ account.companyId ? $t('actions|share') : $t('expressions|activate_members_plan_to_share') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="account.accountType === 'god' ? on : ''">
              <v-btn
                v-if="account.accountType === 'god' && !caseForm.shared"
                icon
                :class="account.accountType === 'god' ? 'mt-4 mr-4' : 'mt-4 mr-n2'"
                v-bind="attrs"
                @click.stop.native="shareLavviraCaseForm(caseForm)"
                v-on="on"
              >
                <v-icon
                  size="22"
                >
                  mdi-share-variant
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('expressions|share_with_all_lavvira_users') }}.</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                v-if="account.accountType === 'god' && caseForm.shared"
                icon
                class="mt-5 mr-5"
                v-bind="attrs"
                @click.stop.native="unShareLavviraCaseForm(caseForm)"
                v-on="on"
              >
                <v-icon
                  size="22"
                  color="error"
                >
                  mdi-share-off
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('actions|stop_sharing') }}</span>
        </v-tooltip>
        <div v-if="account.accountType !== 'god'">
          <div
            v-if="caseForm && caseForm.pinned && caseForm.pinned.findIndex(id => id === account._id)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mr-4 mt-5"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="pinToDashboard(caseForm)"
                >
                  <v-icon
                    color="primary"
                  >
                    mdi-pin-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('actions|add_to_pinboard') }}</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mr-4 mt-5"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="removePinFromDashboard(caseForm)"
                >
                  <v-icon
                    color="primary"
                  >
                    mdi-pin
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('actions|remove_from_pinboard') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <v-card-text
        class="mt-n8"
      >
        <div>
          {{ $t('common|category') }}: {{ translateCategoryIfNeeded(caseForm.category) }}
        </div>
        <div>
          <v-menu
            open-on-hover
            bottom
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                :id="`modelDescription_${cardIndex}`"
                class="text-truncate"
                :style="`max-width: 300px; font-family: ${getBodyFontFam()} !important;`"
                v-bind="attrs"
                v-on="isEllipsisActive(`modelDescription_${cardIndex}`) ? on : ''"
              >
                {{ $t('common|description') }}: {{ caseForm.description }}
              </div>
            </template>

            <v-card
              max-width="350"
            >
              <v-card-text>
                {{ caseForm.description }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div>
          {{ $t('common|date_created') }}: {{ caseForm.createdAt }}
        </div>
      </v-card-text>
      <v-card-actions class="mt-n1">
        <v-btn
          v-if="!caseForm.shared && account.accountType !== 'god'"
          color="primary"
          outlined
          rounded
          small
          class="ml-n2"
          @click.stop.native="openCaseForm(caseForm)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-arrow-top-right
          </v-icon>
          {{ $t('actions|open') }}
        </v-btn>
        <v-btn
          v-if="account.accountType === 'god'"
          color="primary"
          outlined
          rounded
          small
          class="ml-n2"
          @click.stop.native="openCaseForm(caseForm)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-arrow-up-box
          </v-icon>
          {{ $t('actions|open') }}
        </v-btn>
        <v-btn
          v-if="caseForm.shared && account.accountType !== 'god'"
          color="primary"
          outlined
          rounded
          small
          class="ml-n2"
          @click="previewCaseForm(caseForm, lavviraContent)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-arrow-up-box
          </v-icon>
          {{ $t('actions|preview') }}
        </v-btn>
        <v-btn
          v-if="account.accountType !== 'god'"
          color="primary"
          outlined
          rounded
          small
          @click.stop.native="useCaseForm(caseForm)"
        >
          <!-- :disabled="!getAccess(account)" -->
          <v-icon
            small
            class="mr-1"
          >
            mdi-link
          </v-icon>
          {{ $t('actions|use') }}
        </v-btn>
        <v-spacer />
        <div v-if="account.accountType !== 'god' && !caseForm.shared">
          <v-btn
            v-if="getCaseFormSharedWithLength(caseForm) <= 1"
            icon
            class="mr-n3"
            @click.stop.native="deleteCaseForm(caseForm)"
          >
            <v-icon
              size="20"
            >
              mdi-trash-can
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            class="mr-n3"
            @click.stop.native="removeCaseForm(caseForm)"
          >
            <v-icon
              size="20"
            >
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>
        <div v-if="account.accountType === 'god' && caseForm.shared">
          <v-btn
            icon
            class="mr-n3"
            @click.stop.native="deleteCaseForm(caseForm)"
          >
            <v-icon
              size="20"
            >
              mdi-trash-can
            </v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { userHasAccess } from '@/utils/utils'
import cfg from '@/config'
import generalMixin from '@/utils/generalMixin'

export default {
  mixins: [generalMixin],
  props: {
    caseForm: {
      type: Object,
      default: null
    },
    cardIndex: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      lavviraContent: 'lavvira-content',
      cfg
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      actualTabCaseForms: state => state.caseForms.actualTabCaseForms
    }),
    workbench () {
      return this.caseForm.workbenches[0]
    },
    disabledRule () {
      let rule
      if (this.account.companyId) rule = false
      else rule = true
      return rule
    }
  },
  methods: {
    ...mapActions({
      fetchCaseForms: 'caseForms/fetchCaseForms',
      deleteCaseFormAction: 'caseForms/deleteCaseForm',
      updateCaseForm: 'caseForms/updateCaseForm',
      addToast: 'toasts/addToast'
    }),
    openCaseForm (caseForm) {
      this.$router.push({ path: `/models/${caseForm._id}/${this.workbench._id}` })
    },
    previewCaseForm (caseForm, contentType) {
      EventBus.$emit('drawer-overview-case-model', caseForm, contentType)
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|not_available')
      else return category
    },
    async deleteCaseForm (caseForm) {
      const res = await this.$dialog.confirm({
        text: this.$t('case_models|warning_delete_model'),
        title: this.$t('common|warning')
      })
      if (res) {
        try {
          await this.deleteCaseFormAction(caseForm)
        } catch (e) {
          console.error(e, 'e')
        }
      }
    },
    async removeCaseForm (caseForm) {
      const res = await this.$dialog.confirm({
        text: `${this.$t('warning|model_removal')} ${this.actualTabCaseForms.text.bold()}`,
        title: this.$t('common|warning')
      })
      if (res) {
        if (this.actualTabCaseForms) {
          const payload = {
            name: this.actualTabCaseForms.text,
            companyId: this.actualTabCaseForms.company ? this.actualTabCaseForms.company._id : null,
            groupId: this.actualTabCaseForms.group ? this.actualTabCaseForms.group._id : null,
            accId: this.actualTabCaseForms.isMyTab ? this.account._id : null,
            removeCaseModel: true,
            removeFromShared: this.actualTabCaseForms.company ? this.actualTabCaseForms.company._id : this.actualTabCaseForms.group ? this.actualTabCaseForms.group._id : this.actualTabCaseForms.isMyTab ? this.account._id : null,
            isCompany: this.actualTabCaseForms.isCompanyTab,
            isGroup: this.actualTabCaseForms.isGroupTab,
            isMyTab: this.actualTabCaseForms.isMyTab
          }
          try {
            await this.updateCaseForm({
              _id: caseForm._id,
              payload
            })
          } catch (error) {
            console.error(error)
          }
        }
      }
    },
    useCaseForm (caseForm) {
      EventBus.$emit('dialog-add-case-from-case-model', caseForm)
    },
    async pinToDashboard (caseForm) {
      const payload = {
        pinned: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_pinned'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_pin_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseForm) {
      const payload = {
        removePinId: this.account._id
      }
      try {
        await this.updateCaseForm({
          _id: caseForm._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_model_removed_from_dashboard'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_model_removal_from_dashboard_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async shareCaseForm (caseForm) {
      EventBus.$emit('dialog-share-case-model', caseForm, this.actualTabCaseForms)
    },
    async shareLavviraCaseForm (caseForm) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure') + this.$t('warning|case_model_for_all_users'),
        title: this.$t('common|warning')
      })
      if (res) {
        const _id = caseForm._id
        const payload = {
          lavviraCaseModel: true,
          shared: true
        }
        try {
          await this.updateCaseForm({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
    },
    async unShareLavviraCaseForm (caseForm) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure') + this.$t('warning|case_model_for_no_users'),
        title: this.$t('common|warning')
      })
      if (res) {
        const _id = caseForm._id
        const payload = {
          unshareLavviraCaseModel: true,
          shared: false
        }
        try {
          await this.updateCaseForm({ _id, payload })
        } catch (error) {
          console.error(error, 'error')
        }
      }
    },
    getCaseFormSharedWithLength (caseForm) {
      return caseForm.sharedWith.accounts.length + caseForm.sharedWith.company.length + caseForm.sharedWith.groups.length
    },
    getAccess (account) {
      return userHasAccess(account)
    }
  }
}
</script>
